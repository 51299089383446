import { Router, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { inject } from '@angular/core';
import { AuthService, isLoggedInGuard } from '@pozi/authmodule';

export const routes: Routes = [{
  path: 'login',
  component: LoginComponent,
  canActivate: [() => inject(AuthService).$user.value === null ? true : inject(Router).createUrlTree(['.'])]
}, {
  path: '',
  canMatch: [isLoggedInGuard, /*() => inject(AuthService).$user.value !== null ? true : inject(Router).createUrlTree(['login'], { preserveFragment: true })*/],
  loadChildren: () => import('./main/main.routes').then(main => main.routes)
}];
