import { IUser, User } from '@pozi/authmodule';

export class CompanyUser extends User {
  lastName: string;
  firstName: string;
  companies: ICompany[];

  constructor(raw: ICompanyUser) {
    super(raw);
    this.lastName = raw.lastName;
    this.firstName = raw.firstName;
    this.companies = raw.companies;
  }
}

export interface ICompanyUser extends IUser {
  lastName: string;
  firstName: string;
  companies: ICompany[]
}

export interface ICompany {
  name: string,
  id: number,
  sites: ISite[]
}

export interface ISite {
  name: string,
  id: number,
  default: boolean
}
