import { Directive, ElementRef, EmbeddedViewRef, OnChanges, OnDestroy, TemplateRef, input } from '@angular/core';
import tippy, { Instance, Props } from 'tippy.js';

@Directive({
  selector: '[poziTippy]',
  standalone: true,
  exportAs: 'tippy'
})
export class TippyDirective implements OnChanges, OnDestroy {
  tippy = input<TemplateRef<unknown>>();
  tippyOptions = input({});
  templateContext = input<{ [name: string]: unknown; }>({});

  view!: EmbeddedViewRef<unknown>;
  instance: Instance<Props> | null = null;

  constructor(private el: ElementRef<HTMLElement>) { }

  ngOnChanges() {
    this.view = this.tippy()!.createEmbeddedView(this.templateContext());
    this.view.detectChanges();
    if (!this.instance) {
      this.instance = tippy(this.el.nativeElement, { ...this.tippyOptions(), content: this.view.rootNodes[0] });
    } else {
      this.instance.setContent(this.view.rootNodes[0]);
    }
  }

  close() {
    if (this.instance) {
      this.instance.hide();
    }
  }

  ngOnDestroy() {
    this.close();

    if (this.instance) {
      this.instance.destroy();
      this.instance = null;
    }
  }
}
