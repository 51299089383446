import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAuth } from '@pozi/authmodule';
import { CompanyUser, ICompanyUser } from './models/company-user';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { Observable, tap } from 'rxjs';
import { ApiInfo } from './models/api-info';
import { SharedVariablesService } from './shared/shared-variables/shared-variables.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAuth<CompanyUser, ICompanyUser>({
      // debug: true,
      //apiPrefix: 'https://pozi2.test.pozi.tech/api',
      apiPrefix: '/api/',
      authPrefix: 'auth/v1',
      loginEndpoint: '/login',
      userFactory: (raw: ICompanyUser) => new CompanyUser(raw),
      refreshTokenEndpoint: '/refresh-token',
      whitelistedEndpoints: ['auth/v1/oauth2/token', 'auth/v1/refresh-token', 'auth/v1/login', 'system/v1/info'],
      loginPath: 'login'
    }),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: ['/api/auth/v1/oauth2'],
        sendAccessToken: true,
      },
    }), {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: downloadApiInfo,
      deps: [HttpClient, SharedVariablesService]
    }, provideAnimationsAsync()]
};

export function downloadApiInfo(http: HttpClient, sharedVars: SharedVariablesService) {
  return (): Observable<ApiInfo> => http.get<{
    googleMapsApiKey: string,
    oauthProviders: { issuer: string, clientId: string, scope: string, label: string }[],
    apiVersion: { buildDate: string, version: string }
  }>('system/v1/info').pipe(tap(apiInfo => {
    sharedVars.setVar('googleMapsApiKey', apiInfo.googleMapsApiKey);
    sharedVars.setVar('oauthProviders', apiInfo.oauthProviders);
    sharedVars.setVar('apiVersion', apiInfo.apiVersion);
  }));
}
