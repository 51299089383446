import { Injectable } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root'
})
export class SharedVariablesService {

  private variables: {[key: string]: any} = {};

  public setVar(key: string, value: any): void {
    this.variables[key] = value;
  }

  public getVar(key: string): any {
    return (key in this.variables) ? this.variables[key] : null;
  }

  public hasVar(key: string) {
    return (key in this.variables);
  }
}
