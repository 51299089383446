import { CommonModule } from '@angular/common';
import { Component, TemplateRef, forwardRef, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TippyDirective } from '../tippy/tippy.directive';

@Component({
  selector: 'pozi-dropdown',
  standalone: true,
  imports: [CommonModule, TippyDirective],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => DropdownComponent)
  }],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent<T> implements ControlValueAccessor {
  items = input<T[]>([]);
  icon = input<string>('');
  itemGroup = input<string>('');
  showTitle = input<boolean>(true);
  template = input<TemplateRef<undefined> | null>(null);

  disabled = false;
  touched = false;
  selectedItem!: T;
  onChange: (model: T) => void = () => { };
  onTouched: () => void = () => { };
  tippyOptions = {
    theme: 'dropdown',
    interactive: true,
    trigger: 'click',
    appendTo: document.body,
    placement: 'bottom-start',
    offset: [0, 6],
  };

  selectItem(item: T) {
    if (!this.touched && this.selectedItem !== item) {
      this.onTouched();
      this.touched = true;
    }

    this.selectedItem = item;
    this.onChange(this.selectedItem);
  }

  registerOnChange(fn: (model: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(model: T): void {
    this.selectedItem = model;
  }
}
