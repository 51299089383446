import { Component, Inject, LOCALE_ID } from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'pozi-language-switch',
  standalone: true,
  imports: [DropdownComponent, FormsModule],
  templateUrl: './language-switch.component.html',
  styleUrl: './language-switch.component.scss'
})
export class LanguageSwitchComponent {
  languages = ['en', 'hu'];
  selectedLanguage = this.locale;

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  onLanguageChange(language: string) {
    let url = window.location.href;
    if (language === 'en') {
      url = url.replace(/\/hu\//g, '/en/');
    } else {
      url = url.replace(/\/en\//g, '/hu/');
    }
    window.location.replace(url);
  }
}
