<button class="dropdown-button" poziTippy [tippy]="listTemplate" [tippyOptions]="tippyOptions"
  [templateContext]="{items:items(), selectedItem}" [disabled]="disabled">
  <i class="material-symbols-outlined">{{ icon() }}</i>
  @if (selectedItem && showTitle()) {
  <ng-container [ngTemplateOutlet]="template() ? template() : defTemplate"
    [ngTemplateOutletContext]="{$implicit: selectedItem}">
  </ng-container>
  }
</button>

<ng-template #listTemplate>
  <div class="dropdown-content">
    @if (itemGroup()) {
    <span class="dropdown-item-group">{{itemGroup()}}</span>
    }
    <ul class="item-list" role="menu">
      @for (item of items(); track item) {
      <li role="menuitem" tabindex="0" (click)="selectItem(item)" (keydown.enter)="selectItem(item)">
        @if (item === selectedItem) {
        <i class="material-symbols-outlined">done</i>
        } @else {
        <span class="check-placeholder"></span>
        }
        <ng-container *ngTemplateOutlet="template() ? template() : defTemplate; context: {$implicit: item}">
        </ng-container>
      </li>
      }
    </ul>
  </div>
</ng-template>

<ng-template #defTemplate let-item>
  <span>{{ item }}</span>
</ng-template>